@font-face {
  font-family: 'Basier Circle';
  src: local('Basier Circle Bold'), local('BasierCircle-Bold'),
    url('../../assets/fonts/BasierCircle-Bold.woff') format('woff'),
    url('../../assets/fonts/BasierCircle-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
    url('../../assets/fonts/BasierCircle-Regular.woff') format('woff'),
    url('../../assets/fonts/BasierCircle-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: local('Silka Regular'), local('Silka-Regular'), url('../../assets/fonts/Silka-Regular.woff') format('woff'),
    url('../../assets/fonts/Silka-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: local('Basier Circle Regular'), local('BasierCircle-Regular'),
    url('../../assets/fonts/BasierCircle-Regular.woff') format('woff'),
    url('../../assets/fonts/BasierCircle-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: local('Silka Bold'), local('Silka-Bold'), url('../../assets/fonts/Silka-Bold.woff') format('woff'),
    url('../../assets/fonts/Silka-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: local('Silka Bold'), local('Silka-Bold'), url('../../assets/fonts/Silka-Bold.woff') format('woff'),
    url('../../assets/fonts/Silka-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basier Circle';
  src: local('Basier Circle Bold'), local('BasierCircle-Bold'),
    url('../../assets/fonts/BasierCircle-Bold.woff') format('woff'),
    url('../../assets/fonts/BasierCircle-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silka';
  src: local('Silka Regular'), local('Silka-Regular'), url('../../assets/fonts/Silka-Regular.woff') format('woff'),
    url('../../assets/fonts/Silka-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.desktop-only {
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 9999999;
  left: 0;
  position: fixed;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 150%;
  padding: 48px;
}

html,
body {
  font-family: 'BasierCircle', 'Silka', 'Roboto', Arial, serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Silka';
  font-weight: bold;
}
